<nav class="vw-100 position-fixed d-flex justify-content-between align-items-center" *ngIf="!mobileSizeService.isMobileSize"
     style="top: 0; left: 0; padding: 16px 64px;">
  <div style="width: 315px;min-height: 54px;"><!-- logo center --></div>
  <img style="cursor: pointer;" [src]="constantService.configuration.navLogSrc" (click)="logoClicked()" alt="Logo"/>
  <div class="d-flex" style="gap: 16px;" >
    <button [ngClass]="constantService.configuration.navButtonClass" class="white-text" (click)="contactDetailDialogService.openFeedbackFlow()">
      Share your Feedback
    </button>
    <button [ngClass]="constantService.configuration.navButtonClass" class="white-text" (click)="contactUsClicked()">
      {{ constantService.configuration.navContactButtonLabel }}
    </button>
  </div>

</nav>
<nav class="vw-100 position-fixed d-flex justify-content-between align-items-center"  style="top: 0; left: 0; padding: 16px 32px; height: 80px;" *ngIf="mobileSizeService.isMobileSize">
  <img style="cursor: pointer;" [src]="constantService.configuration.navLogSrc" (click)="logoClicked()" alt="Logo"/>
  <div>

  </div>
</nav>
