<div
  [style.width.px]="baseDimensionWidth"
  [style.height.px]="baseDimensionHeight"
  style="border-radius: 26px;
background: rgba(236,237,239,0.88);"
>
  <div class="content-image"
       style="min-height: 200px; min-width: 100%; border-radius: 25px 25px 0 0"
       [style.background-image]="'url(' + mainImageLink + ')'"
       [style.min-height.px]="mainImageHeight"
       [style.min-width.px]="mainImageWidth"
  >

  </div>
  <div class="d-flex justify-content-center align-items-center" style="height: 32px; width: 100%;gap: 8px;">
    <div style="width: 8px;
height: 8px;background: rgb(189,190,196); border-radius: 8px;" *ngFor="let dot of item.imageUrls"
         [class.bg-black]="dot == mainImageLink"></div>
  </div>
  <div *ngIf="!item.isImageOnlyContent" style="padding-right: 20px; padding-left: 20px; padding-bottom: 16px;">
    <div style="color: #010205;
font-family: Manrope;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 24px */">
      {{ item.contentId }}
    </div>
    <div style="color: rgba(1, 2, 5, 0.60);
font-family: Manrope;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 19.2px */">
      {{ item.price }}
    </div>
    <div class="d-flex" style="margin-top: 12px;">
      <div *ngFor="let spec of item.table">
        <div style="color: rgba(1, 2, 5, 0.60);
font-family: Manrope;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 12px */">
          {{ spec.key }}
        </div>
        <div style="color: #010205;
font-family: Manrope;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 110%; /* 17.6px */
letter-spacing: -0.176px;"
        >
          {{ spec.value }}
        </div>
      </div>
    </div>
  </div>
</div>
